import { Text, VSpacingContainer, Cell, CellText, VSpacing, Link } from '@hh.ru/magritte-ui';
import { H3 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import BlokoText, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

interface ContactPhonesProps {
    showHeader?: boolean;
    formatted?: boolean;
}

const TrlKeys = {
    contact: 'employer.auth.contact',
    regions: {
        msk: 'helpPhone.title.msk',
        spb: 'helpPhone.title.spb',
        regions: 'helpPhone.title.otherRegions',
        city: 'helpPhone.title.city',
        mobile: 'helpPhone.title.mobile',
    },
    andRegions: 'employer.auth.city.region',
    helpPhone: 'newEmployer.helpphonelabel',
};

const ContactPhones: TranslatedComponent<ContactPhonesProps> = ({ trls, showHeader = true, formatted }) => {
    const contactPhones = useSelector((state) => state.contactPhones);
    const isMagritte = useMagritte();

    if (!contactPhones?.length) {
        return null;
    }

    const blokoSinglePhone = (
        <div className="helpphone">
            <BlokoText size={TextSize.Small}>{formatNewLine(trls[TrlKeys.helpPhone])}</BlokoText>
            <VSpacing default={8} />
            <BlokoText size={TextSize.Large}>
                {contactPhones[0].country} {contactPhones[0].city} {contactPhones[0].number}
            </BlokoText>
        </div>
    );

    const magritteSinglePhone = (
        <div>
            <Text typography="subtitle-1-semibold">{formatNewLine(trls[TrlKeys.helpPhone])}</Text>
            <VSpacing default={8} />
            <Text typography="paragraph-2-regular" style="secondary">
                {contactPhones[0].country} {contactPhones[0].city} {contactPhones[0].number}
            </Text>
        </div>
    );

    if (contactPhones.length === 1) {
        return isMagritte ? magritteSinglePhone : blokoSinglePhone;
    }

    if (!isMagritte) {
        return (
            <>
                {showHeader && (
                    <>
                        <H3>{trls[TrlKeys.contact]}</H3>
                        <VSpacing default={12} />
                    </>
                )}

                {contactPhones.map((phone) => (
                    <div className={formatted ? 'helpphone_formatted' : undefined} key={phone.postfix}>
                        <div className="helpphone-title">
                            {trls[TrlKeys.regions[phone.postfix as keyof typeof TrlKeys.regions]]}
                            {['msk', 'spb'].includes(phone.postfix) && trls[TrlKeys.andRegions]}
                        </div>
                        <BlokoLink
                            kind={LinkKind.Tertiary}
                            href={`tel:${phone.country}${phone.city}${phone.number.replaceAll('-', '')}`}
                        >
                            {phone.country} {phone.city} {phone.number}
                        </BlokoLink>
                    </div>
                ))}
            </>
        );
    }

    return (
        <VSpacingContainer default={16}>
            {showHeader && <Text typography="subtitle-1-semibold">{trls[TrlKeys.contact]}</Text>}
            {contactPhones.map((phone) => (
                <Cell key={phone.postfix}>
                    <CellText type="subtitle">
                        {trls[TrlKeys.regions[phone.postfix as keyof typeof TrlKeys.regions]]}
                        {['msk', 'spb'].includes(phone.postfix) && trls[TrlKeys.andRegions]}
                    </CellText>
                    <CellText>
                        <Link
                            Element="a"
                            style="neutral"
                            typography="label-2-regular"
                            href={`tel:${phone.country}${phone.city}${phone.number.replaceAll('-', '')}`}
                        >
                            {`${phone.country} ${phone.city} ${phone.number}`}
                        </Link>
                    </CellText>
                </Cell>
            ))}
        </VSpacingContainer>
    );
};

export default translation(ContactPhones);
