import { ComponentProps } from 'react';
import { Field } from 'react-final-form';

import { PasswordInput, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useAutofocusRenderOnce } from 'src/hooks/useAutofocus';

const TrlKeys = {
    password: 'login.password',
    valueMissing: 'form.error.valueMissing',
};

interface PasswordFieldProps {
    placeholder?: string;
}

const PasswordField: TranslatedComponent<PasswordFieldProps> = ({ trls, placeholder }) => {
    const getAutofocusRef = useAutofocusRenderOnce();

    return (
        <Field
            name="password"
            render={({ input, meta }) => (
                <>
                    <PasswordInput
                        {...(input as ComponentProps<typeof PasswordInput>)}
                        data-qa="login-input-password"
                        invalid={meta.invalid && meta.submitFailed}
                        {...getAutofocusRef(input.value as string)}
                        placeholder={trls[TrlKeys.password] || placeholder}
                        size="large"
                        errorMessage={meta.error ? trls[TrlKeys[meta.error as keyof typeof TrlKeys]] : null}
                    />
                    <VSpacing default={8} />
                </>
            )}
        />
    );
};

export default translation(PasswordField);
