import { useEffect, useRef } from 'react';

import { Cell, CellText, Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    trustThisSource: 'auth.twoFactor.totp.trustThisSource',
};

interface TwoFactorPushProps {
    pushRedirectUrl?: string;
    onSubmit: () => Promise<void>;
}

interface IframeEvent {
    eventId: string;
    payload: string;
}

const TwoFactorPush: TranslatedComponent<TwoFactorPushProps> = ({ onSubmit, pushRedirectUrl, trls }) => {
    const pushCodeFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleIframeEvents = async (event: MessageEvent) => {
            if (typeof event.data !== 'string') {
                return;
            }
            let data: IframeEvent;
            try {
                data = JSON.parse(event.data) as IframeEvent;
            } catch (e) {
                console.error(e);
                return;
            }
            if (data.eventId === 'PushTokenReceive') {
                const code = pushCodeFieldRef.current;
                if (code !== null) {
                    code.value = data.payload;
                    await onSubmit();
                }
            }
        };
        window.addEventListener('message', handleIframeEvents);

        return () => {
            window.removeEventListener('message', handleIframeEvents);
        };
    }, [onSubmit]);

    return (
        <>
            <input type="hidden" ref={pushCodeFieldRef} data-qa="login-form-push-code" name="pushCode" />
            <iframe src={pushRedirectUrl} height={600} />
            <Cell left={<Checkbox defaultChecked={false} data-qa="push-trust-this-source" name="trust_this_source" />}>
                <CellText>{trls[TrlKeys.trustThisSource]}</CellText>
            </Cell>
        </>
    );
};

export default translation(TwoFactorPush);
