import { useMemo } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { BrandedButton, VSpacing } from '@hh.ru/magritte-ui';
import { ButtonIconPosition, ButtonAppearance } from 'bloko/blocks/button';
import { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Button from 'src/components/MagritteRedesignComponents/Button';
import Text from 'src/components/MagritteRedesignComponents/Text';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import useOnOffState from 'src/hooks/useOnOffState';
import { SocialNetworks, SocialNetworksCode } from 'src/models/LoginForm';

import SocialButton, {
    SOCIAL_LINK_ICONS,
    MAGRITTE_SOCIAL_LINK_ICONS,
    SocialButtonProps,
} from 'src/components/SignInSignUpFlow/SocialButton';
import SocialButtonsModal from 'src/components/SignInSignUpFlow/SocialButtonsModal';
import { SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE } from 'src/components/SignInSignUpFlow/types';

import styles from './social-buttons.less';

interface SocialButtonsProps {
    socialNetworks: SocialNetworks[];
    isRegistration?: boolean;
    handleSocialClick?: SocialButtonProps['handleSocialClick'];
}

const TrlKeys = {
    auth: 'social.networks.auth.with',
    registration: 'social.networks.registration.with',
};

const SOCIAL_ICONS_ON_BUTTON_MAX_LENGTH = 3;
const SOCIAL_ICONS_LONELY_MAX_LENGTH = 1;

const SocialButtons: TranslatedComponent<SocialButtonsProps> = ({
    trls,
    socialNetworks,
    isRegistration,
    handleSocialClick,
}) => {
    const [modalIsVisible, showModal, hideModal] = useOnOffState(false);
    const isMagritte = useMagritte();

    const count = socialNetworks.length;

    const renderIcon = useMemo(() => {
        const icons = isMagritte ? MAGRITTE_SOCIAL_LINK_ICONS : SOCIAL_LINK_ICONS;

        return (
            <>
                {socialNetworks.slice(0, SOCIAL_ICONS_ON_BUTTON_MAX_LENGTH).map((item) => {
                    return (
                        <span className={styles.icon} key={item.code}>
                            {icons[item.code]}
                        </span>
                    );
                })}
                {count > SOCIAL_ICONS_ON_BUTTON_MAX_LENGTH && (
                    <span className={styles.icon}>
                        <Text
                            typography="subtitle-1-semibold"
                            blokoComponentProps={{
                                importance: TextImportance.Tertiary,
                            }}
                        >
                            +{count - 3}
                        </Text>
                    </span>
                )}
            </>
        );
    }, [isMagritte, socialNetworks, count]);

    const showModalAndAnalytics = () => {
        Analytics.sendHHEventButtonClick('show_social_modal');
        showModal();
    };

    const shouldShowSocialText = useMemo(() => {
        return (
            count === SOCIAL_ICONS_LONELY_MAX_LENGTH && socialNetworks[0]?.code !== SocialNetworksCode.TinkoffBusiness
        );
    }, [count, socialNetworks]);

    return (
        <>
            <div className={styles.text}>
                <VSpacing default={isMagritte ? 12 : 8} />
                {shouldShowSocialText && (
                    <>
                        <VSpacing default={8} />
                        <Text
                            typography="label-4-regular"
                            style="tertiary"
                            blokoComponentProps={{
                                importance: TextImportance.Tertiary,
                                size: TextSize.Small,
                            }}
                        >
                            {trls[isRegistration ? TrlKeys.registration : TrlKeys.auth]}
                        </Text>
                        <VSpacing default={8} />
                    </>
                )}

                {count === SOCIAL_ICONS_LONELY_MAX_LENGTH ? (
                    <SocialButton
                        isRegistration={isRegistration}
                        {...socialNetworks[0]}
                        handleSocialClick={handleSocialClick}
                    />
                ) : (
                    <>
                        {isMagritte ? (
                            <BrandedButton
                                size="large"
                                data-qa="social-registration-button"
                                service={socialNetworks.map(
                                    (social) => SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[social.code]
                                )}
                                onClick={showModalAndAnalytics}
                            >
                                {trls[isRegistration ? TrlKeys.registration : TrlKeys.auth]}
                            </BrandedButton>
                        ) : (
                            <Button
                                mode="secondary"
                                size="large"
                                style="neutral"
                                data-qa="social-registration-button"
                                stretched
                                icon={renderIcon}
                                onClick={showModalAndAnalytics}
                                blokoComponentProps={{
                                    appearance: ButtonAppearance.Outlined,
                                    iconPosition: ButtonIconPosition.Right,
                                }}
                            >
                                {trls[isRegistration ? TrlKeys.registration : TrlKeys.auth]}
                            </Button>
                        )}

                        <SocialButtonsModal
                            isVisible={modalIsVisible}
                            handleCloseModal={hideModal}
                            socialNetworks={socialNetworks}
                            handleSocialClick={handleSocialClick}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default translation(SocialButtons);
