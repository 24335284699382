import { FC, SyntheticEvent, MouseEvent } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { BrandedButton } from '@hh.ru/magritte-ui';
import {
    EsiaBrandSize24,
    VkBrandSize24,
    MailBrandSize24,
    OkBrandSize24,
    GoogleBrandSize24,
    TinkoffIdBrandSize24,
} from '@hh.ru/magritte-ui/service';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoButton, { ButtonIconPosition as BlokoButtonIconPosition } from 'bloko/blocks/button';

import { useMagritte } from 'src/hooks/useMagritte';
import { SocialNetworks, SocialNetworksCode } from 'src/models/LoginForm';

import { SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE } from 'src/components/SignInSignUpFlow/types';

import styles from './social-button.less';

export const SOCIAL_LINK_ICONS = {
    [SocialNetworksCode.MAIL]: <MailBrandSize24 />,
    [SocialNetworksCode.OK]: <OkBrandSize24 />,
    [SocialNetworksCode.VK]: <VkBrandSize24 />,
    [SocialNetworksCode.GPLUS]: <GoogleBrandSize24 />,
    [SocialNetworksCode.ESIA]: <EsiaBrandSize24 />,
    [SocialNetworksCode.TinkoffBusiness]: (
        <span className={styles.tinkoff}>
            <TinkoffIdBrandSize24 />
        </span>
    ),
};

export const MAGRITTE_SOCIAL_LINK_ICONS = {
    [SocialNetworksCode.MAIL]: <MailBrandSize24 />,
    [SocialNetworksCode.OK]: <OkBrandSize24 />,
    [SocialNetworksCode.VK]: <VkBrandSize24 />,
    [SocialNetworksCode.GPLUS]: <GoogleBrandSize24 />,
    [SocialNetworksCode.ESIA]: <EsiaBrandSize24 />,
    [SocialNetworksCode.TinkoffBusiness]: <TinkoffIdBrandSize24 />,
};

export interface SocialButtonProps extends SocialNetworks {
    handleSocialClick?: (code: SocialNetworks['code'], event: SyntheticEvent) => void;
    isRegistration?: boolean;
}

const SocialButton: FC<SocialButtonProps> = ({ code, url, trl, handleSocialClick }) => {
    const isMagritte = useMagritte();
    const isBrandedButtonExist = Object.keys(SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE).includes(code);

    const commonProps = {
        to: url,
        Element: SPALink,
        'data-qa': `social-button-${code}`,
        onClick: (event: MouseEvent) => {
            Analytics.sendHHEventButtonClick('authorize_by_social_button', {
                type: code,
            });
            handleSocialClick?.(code, event);
        },
    };

    if (!isMagritte) {
        return (
            <BlokoButton
                {...commonProps}
                stretched
                iconPosition={BlokoButtonIconPosition.Right}
                icon={SOCIAL_LINK_ICONS[code]}
            >
                {trl}
            </BlokoButton>
        );
    }

    if (!isBrandedButtonExist) {
        return null;
    }

    return (
        <BrandedButton
            service={SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[code]}
            size="large"
            mode="secondary"
            {...commonProps}
        >
            {trl}
        </BrandedButton>
    );
};

export default SocialButton;
