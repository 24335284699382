import { FC } from 'react';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import { TwoFactorCheckStatus } from 'src/components/AccountLogin/types';

import TwoFactorCodeSender from 'src/components/AccountLogin/steps/TwoFactorStep/TwoFactorCodeSender';
import TwoFactorPush from 'src/components/AccountLogin/steps/TwoFactorStep/TwoFactorPush';
import TwoFactorTotp from 'src/components/AccountLogin/steps/TwoFactorStep/TwoFactorTotp';

interface TwoFactorStepProps {
    onResendCode: () => Promise<void>;
    onNoAccessToEmail: () => void;
    pushRedirectUrl?: string;
    onSubmit: () => Promise<void>;
}

const TwoFactorStep: FC<TwoFactorStepProps> = ({ onResendCode, onNoAccessToEmail, pushRedirectUrl, onSubmit }) => {
    const { verificationType } = useLoginContext();

    if (
        verificationType === TwoFactorCheckStatus.TwoFactorTotpCheck ||
        verificationType === TwoFactorCheckStatus.TwoFactorTotpCheckWrongCode
    ) {
        return <TwoFactorTotp verificationType={verificationType} />;
    } else if (verificationType === TwoFactorCheckStatus.TwoFactorPushCheck) {
        return <TwoFactorPush onSubmit={onSubmit} pushRedirectUrl={pushRedirectUrl} />;
    }

    return <TwoFactorCodeSender onResendCode={onResendCode} onNoAccessToEmail={onNoAccessToEmail} />;
};

export default TwoFactorStep;
