import { Field } from 'react-final-form';

import { Text, Checkbox, HSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './remember-me.less';

const TrlKeys = {
    remember: 'account.login.remember',
};

const RememberMe: TranslatedComponent = ({ trls }) => {
    const authParams = useSelector((state) => state.authUrl);

    if (!authParams) {
        return null;
    }

    return (
        <Field
            type="checkbox"
            name={authParams['remember-field-name']}
            render={({ input }) => (
                <label className={styles.checkboxContainer}>
                    <Checkbox
                        {...input}
                        name={undefined}
                        dataQaCheckbox="account-login-remember"
                        aria-label={trls[TrlKeys.remember]}
                    />
                    <HSpacing default={4} />
                    <Text typography="label-2-regular">{trls[TrlKeys.remember]}</Text>
                </label>
            )}
        />
    );
};

export default translation(RememberMe);
