import { useField } from 'react-final-form';

import { Title } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Header from 'src/components/AccountLogin/components/Title/Header';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';

const TrlKeys = {
    header: 'login.form.otp.header',
    emailHeader: 'employer.login.otp.header',
    subHeader: 'otp.confirm.sub.header',
    employerOtpSubHeader: 'employer.login.otp.subHeader',
};

const VerificationCodeStepHeader: TranslatedComponent = ({ trls }) => {
    const isMagritte = useMagritte();
    const username = useField('username').input.value as string;
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username);

    const titleText = isEmail
        ? trls[TrlKeys.emailHeader]
        : format(trls[TrlKeys.header], {
              '{0}': username,
          });

    const subHeaderText = isEmail
        ? format(trls[TrlKeys.employerOtpSubHeader], {
              '{email}': username,
          })
        : trls[TrlKeys.subHeader];

    if (!isMagritte) {
        <Header subheader={subHeaderText}>{titleText}</Header>;
    }
    return (
        <Title Element="h1" size="small" description={subHeaderText}>
            {titleText}
        </Title>
    );
};

export default translation(VerificationCodeStepHeader);
