import { FC } from 'react';

import PasswordRecovery from 'src/components/AccountLogin/components/PasswordRecovery';

const MailruError: FC = () => {
    return (
        <>
            <PasswordRecovery button mode="primary" />
        </>
    );
};

export default MailruError;
