import { MutableState, Tools } from 'final-form';

export const EMPTY_VALUE = undefined;
const hiddenValues: Record<string, unknown> = {};

export type CustomMutator = <FormValues, InitialFormValues extends Partial<FormValues>>(
    args: string[],
    state: MutableState<FormValues, InitialFormValues>,
    tools: Tools<FormValues, InitialFormValues>
) => unknown;

const setValue: CustomMutator = ([key, value], state, { changeValue, getIn }) => {
    const currentValue = getIn(state.formState.values, key) as typeof value | undefined;
    if (currentValue !== value) {
        changeValue(state, key, () => value);
    }
};

const touchValue: CustomMutator = ([key], state, { changeValue, getIn }) => {
    const currentValue = getIn(state.formState.values, key) as unknown;
    changeValue(state, key, () => currentValue);
};

const removeValue: CustomMutator = ([key], state, { changeValue, getIn }) => {
    const currentValue = getIn(state.formState.values, key) as unknown;
    if (currentValue !== undefined) {
        changeValue(state, key, () => undefined);
    }
};

const setHiddenValue: CustomMutator = ([key, value]) => {
    hiddenValues[key] = value;
};

const removeHiddenValues: CustomMutator = ([valueKeys]) => {
    for (const valueKey of valueKeys) {
        delete hiddenValues[valueKey];
    }
};

const hideValues: CustomMutator = ([valueKeys], state, { changeValue, getIn }) => {
    for (const valueKey of valueKeys) {
        const value = getIn(state.formState.values, valueKey) as unknown;
        if (value !== EMPTY_VALUE) {
            hiddenValues[valueKey] = value;
            changeValue(state, valueKey, () => EMPTY_VALUE);
        }
    }
};

const showValues: CustomMutator = ([valueKeys], state, { changeValue, getIn }) => {
    for (const valueKey of valueKeys) {
        const currentValue = getIn(state.formState.values, valueKey) as unknown;
        if (currentValue === EMPTY_VALUE) {
            changeValue(state, valueKey, () => hiddenValues[valueKey]);
        }
    }
};

const setFieldTouched: CustomMutator = ([key], { fields }) => {
    fields[key].touched = true;
};
const setFieldAdvice: CustomMutator = ([key, value], { fields }) => {
    if (fields?.[key]) {
        fields[key].data = fields[key].data || {};
        fields[key].data.advice = value;
    }
};

export default {
    setValue,
    touchValue,
    removeValue,
    setHiddenValue,
    hideValues,
    showValues,
    setFieldTouched,
    setFieldAdvice,
    removeHiddenValues,
};
