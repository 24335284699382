import { FocusEvent } from 'react';
import { Field } from 'react-final-form';

import { Input, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import translation from 'src/components/translation';
import { useAutofocusRenderOnce } from 'src/hooks/useAutofocus';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import useVkAppIntegrationFocus from 'src/hooks/useVkAppIntegrationFocus';
import { LOGIN_FORM } from 'src/hooks/useVkAppIntegrationReady';

const TrlKeys = {
    login: 'login.placeholder',
    employerLogin: 'login.employer.placeholder',
    employerLoginZP: 'login.employer.placeholder.zarplata',
    valueMissing: 'form.error.valueMissing',
};

interface UsernameFieldProps {
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const UsernameField: TranslatedComponent<UsernameFieldProps> = ({ trls, onBlur }) => {
    const isZP = useIsZarplataPlatform();
    const { isEmployerForm } = useLoginContext();
    const getAutofocusRef = useAutofocusRenderOnce();
    let loginPlaceholder: string = isEmployerForm ? trls[TrlKeys.employerLogin] : trls[TrlKeys.login];
    if (isZP) {
        loginPlaceholder = trls[TrlKeys.employerLoginZP];
    }

    const handleFocus = useVkAppIntegrationFocus(LOGIN_FORM);

    return (
        <Field
            name="username"
            type="text"
            onFocus={handleFocus}
            onBlur={onBlur}
            render={({ input, meta, ...otherProps }) => (
                <>
                    <Input
                        {...input}
                        autoCapitalize="off"
                        autoCorrect="off"
                        data-qa="login-input-username"
                        inputMode="email"
                        invalid={meta.invalid && meta.submitFailed}
                        placeholder={loginPlaceholder}
                        spellCheck="false"
                        size="large"
                        {...getAutofocusRef(input.value as string)}
                        {...otherProps}
                    />
                    <VSpacing default={12} />
                </>
            )}
        />
    );
};

export default translation(UsernameField);
