import { ReactNode } from 'react';
import { useFormState } from 'react-final-form';

import { Link, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const IGNORED_ERRORS = ['2fa_check', '2fa_check_account_blocked', 'esiaAccountNotLinked', 'oauthConnectInternalError'];

const TrlKeys = {
    passwordChange: 'error.loginpage.passwordChange',
    sessionLimit: 'error.loginpage.sessionLimit',
    why: 'informer.unoLogin.why',
    error: 'error.happened.mobile',
    BAD_LOGIN: 'login.form.email.incorrect',
};

const LoginError: TranslatedComponent = ({ trls }) => {
    const { dirtySinceLastSubmit } = useFormState();
    const loginError = useSelector((state) => state.loginError);

    if (!loginError || IGNORED_ERRORS.includes(loginError?.code) || dirtySinceLastSubmit) {
        return null;
    }

    let error = trls[TrlKeys.error] as string | ReactNode;

    if (loginError.trl) {
        error = loginError.trl;
    }

    if (loginError.code === 'password-change') {
        error = trls[TrlKeys.passwordChange];
    }

    if (loginError.code === 'max-sessions-limit-exceed') {
        error = (
            <>
                <div>{trls[TrlKeys.sessionLimit]}</div>
                <Link Element={SPALink} to="/article/14249" target="_blank" typography="label-3-regular">
                    {trls[TrlKeys.why]}
                </Link>
            </>
        );
    }

    if (!loginError.trl && TrlKeys[loginError.code as keyof typeof TrlKeys]) {
        error = trls[TrlKeys[loginError.code as keyof typeof TrlKeys]];
    }

    return (
        <div>
            <Text style="negative" typography="label-3-regular" data-qa="account-login-error">
                {error}
            </Text>
            <VSpacing default={24} />
        </div>
    );
};

export default translation(LoginError);
