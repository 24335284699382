import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    resendCodeError: 'auth.twoFactor.error.resend',
};

const ResendErrorNotification: TranslatedComponent = ({ trls }) => {
    return <div>{trls[TrlKeys.resendCodeError]}</div>;
};

export default {
    Element: translation(ResendErrorNotification),
    kind: 'error',
    autoClose: true,
    autoCloseDelay: 3000,
};
