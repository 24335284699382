import Supports from 'bloko/common/supports';

export const DEFAULT_FALLBACK_DELAY_MS = 300;

export interface DeepLinkConstructorParams {
    fallbackUrl: string;
    fallbackDelay?: number;
    androidUrl?: string;
    iosUrl?: string;
}

export class DeepLink {
    private iframe: HTMLIFrameElement;
    private fallbackUrl: DeepLinkConstructorParams['fallbackUrl'];
    private fallbackDelay: DeepLinkConstructorParams['fallbackDelay'];
    private androidUrl: DeepLinkConstructorParams['androidUrl'];
    private iosUrl: DeepLinkConstructorParams['iosUrl'];

    constructor({
        fallbackUrl,
        fallbackDelay = DEFAULT_FALLBACK_DELAY_MS,
        androidUrl,
        iosUrl,
    }: DeepLinkConstructorParams) {
        this.iframe = document.createElement('iframe');
        this.iframe.classList.add('g-hidden');

        this.fallbackUrl = fallbackUrl;
        this.fallbackDelay = fallbackDelay;
        this.androidUrl = androidUrl;
        this.iosUrl = iosUrl;
    }

    open(deepUrl: string): void {
        if (Supports.ios() && this.iosUrl) {
            window.location.assign(this.iosUrl);
            return;
        }

        if (Supports.android() && this.androidUrl) {
            window.location.assign(this.androidUrl);
            return;
        }

        const fallbackTimer = this.fallbackUrl
            ? window.setTimeout(() => {
                  window.open(this.fallbackUrl, '_blank');
              }, this.fallbackDelay)
            : undefined;

        const deeplinkClear = () => {
            document.body.contains(this.iframe) && document.body.removeChild(this.iframe);
            window.clearTimeout(fallbackTimer);
            window.removeEventListener('blur', deeplinkClear);
        };
        window.addEventListener('blur', deeplinkClear);

        this.iframe.src = deepUrl;
        document.body.appendChild(this.iframe);
    }
}
