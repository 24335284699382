import { useEffect, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';

import { format } from '@hh.ru/front-static-app';
import { VSpacing, PincodeInput } from '@hh.ru/magritte-ui';
import { FormError } from 'bloko/blocks/form';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Input from 'src/components/MagritteRedesignComponents/Input';
import translation from 'src/components/translation';
import useAutofocus from 'src/hooks/useAutofocus';
import { useMagritte } from 'src/hooks/useMagritte';
import { Verification } from 'src/models/applicant/auth';

import CodeError from 'src/components/OTP/CodeError';
import useTimeoutTimer from 'src/components/OTP/useTimeoutTimer';

interface CodeInputProps {
    verificationStatus?: Verification | null;
    isEmployer?: boolean;
    dataQa?: string;
}

const TrlKeys = {
    employerPlaceholder: 'otp.employer.placeholder',
    applicantPlaceholder: 'otp.applicant.placeholder',
    noCode: 'login.form.otp.noCode',
    errors: {
        default: 'account.connect.merge_by_code.email.error.unknown',
        CODE_NOT_FOUND: 'account.connect.merge_by_code.email.error.CODE_NOT_FOUND',
        CONFIRM_CODE_BLOCKED: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED',
        CONFIRM_CODE_BLOCKED_PHONE: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED.phone',
        WRONG_CODE: 'account.connect.merge_by_code.email.error.WRONG_CODE',
        CODE_EXPIRED: 'account.connect.merge_by_code.email.error.CODE_EXPIRED',
        ACCOUNT_BLOCKED: 'error.employer.discarded',
        valueMissing: 'form.error.valueMissing',
        PASSWORD_LIKE: 'login.form.otp.passwordLike',
    },
};

const getCodeBlockedErrorText = (timer: number, trls: LangTrls) => {
    const errorText = trls[TrlKeys.errors.CONFIRM_CODE_BLOCKED];
    if (timer > 0) {
        return format(errorText, {
            '{0}': timer,
        });
    }
    return null;
};

const fieldName = 'code';

const CodeInput: TranslatedComponent<CodeInputProps> = ({ trls, verificationStatus, isEmployer, dataQa }) => {
    const isMagritte = useMagritte();
    const form = useForm();
    const code = useField(fieldName).input.value as string;
    const inputRefToFocus = useAutofocus();
    const accountType = verificationStatus?.accountType || (isEmployer ? 'EMPLOYER' : 'APPLICANT');
    const [nextConfirmTimestamp, setNextConfirmTimestamp] = useState<number>();
    const timer = useTimeoutTimer(nextConfirmTimestamp || 0);

    useEffect(() => {
        const nextTimestamp =
            verificationStatus?.nextConfirmTime?.['@timestamp'] || verificationStatus?.nextConfirmTime?.timestamp;
        if (nextTimestamp) {
            setNextConfirmTimestamp(nextTimestamp);
        }
    }, [verificationStatus?.nextConfirmTime]);

    const digitsCount = accountType === 'APPLICANT' ? 4 : 6;

    useEffect(() => {
        if (isMagritte && code?.length === digitsCount) {
            void form?.submit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    return (
        <Field
            name={fieldName}
            render={({ input, meta }) => {
                const errorKey = (meta.error || meta.submitError) as keyof typeof TrlKeys.errors;
                const translatedError = errorKey && trls[TrlKeys.errors[errorKey]];
                const errorMessage =
                    verificationStatus?.key === 'CONFIRM_CODE_BLOCKED'
                        ? getCodeBlockedErrorText(timer, trls)
                        : translatedError;
                return (
                    <>
                        {isMagritte ? (
                            <PincodeInput
                                {...input}
                                size={digitsCount === 6 ? 'medium' : 'large'}
                                digitsCount={digitsCount}
                                invalid={meta.invalid && meta.submitFailed && Boolean(errorMessage)}
                                errorMessage={errorMessage ?? undefined}
                                data-qa={dataQa}
                                ref={inputRefToFocus}
                            />
                        ) : (
                            <Input
                                {...input}
                                size="large"
                                autoFocus
                                invalid={meta.invalid && meta.submitFailed && Boolean(errorMessage)}
                                errorMessage={errorMessage}
                                data-qa={dataQa}
                                placeholder={
                                    accountType === 'APPLICANT'
                                        ? trls[TrlKeys.applicantPlaceholder]
                                        : trls[TrlKeys.employerPlaceholder]
                                }
                            />
                        )}

                        {!isMagritte && meta.invalid && meta.submitFailed && Boolean(errorMessage) && (
                            <>
                                <VSpacing default={8} />
                                {!verificationStatus || verificationStatus?.key === 'CODE_SEND_OK' ? (
                                    <FormError show>{translatedError}</FormError>
                                ) : (
                                    <CodeError verification={verificationStatus || null} />
                                )}
                            </>
                        )}
                    </>
                );
            }}
        />
    );
};

export default translation(CodeInput);
