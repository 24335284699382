import { ComponentProps } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import { AuthMethod } from 'src/components/AccountLogin/types';
import Button from 'src/components/MagritteRedesignComponents/Button';
import Link from 'src/components/MagritteRedesignComponents/Link';
import translation from 'src/components/translation';

const TrlKeys = {
    actionByCode: 'login.action.by.code',
    actionByPassword: 'login.action.by.password',
};

interface AuthMethodSwitcherProps {
    mode?: 'button' | 'link';
    buttonProps?: Partial<Pick<ComponentProps<typeof Button>, 'style' | 'mode' | 'blokoComponentProps' | 'size'>>;
}

const AuthMethodSwitcher: TranslatedComponent<AuthMethodSwitcherProps> = ({
    trls,
    mode = 'link',
    buttonProps = {},
}) => {
    const { onByCode, onByPassword, authMethod } = useLoginContext();
    const handleClick = () => {
        if (authMethod === AuthMethod.ByCode) {
            onByPassword?.();
            return;
        }
        onByCode?.();
    };
    const text = authMethod === AuthMethod.ByCode ? trls[TrlKeys.actionByPassword] : trls[TrlKeys.actionByCode];
    const dataQa = `expand-login-${authMethod === AuthMethod.ByCode ? AuthMethod.ByPassword : AuthMethod.ByCode}`;
    if (mode === 'button') {
        return (
            <Button
                style="accent"
                mode="secondary"
                size="large"
                stretched
                {...buttonProps}
                onClick={handleClick}
                data-qa={dataQa}
            >
                {text}
            </Button>
        );
    }
    return (
        <Link data-qa={dataQa} typography="label-2-regular" onClick={handleClick}>
            {text}
        </Link>
    );
};

export default translation(AuthMethodSwitcher);
