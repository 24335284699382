import { FC, ReactNode, PropsWithChildren } from 'react';

import { Title, VSpacing } from '@hh.ru/magritte-ui';

interface HeaderProps {
    dataQa?: string;
    subheader: ReactNode | string;
}

const Header: FC<HeaderProps & PropsWithChildren> = ({ children, dataQa, subheader }) => {
    if (!children) {
        return null;
    }

    return (
        <>
            <Title Element="h2" size="medium" alignment="left" description={subheader} dataQaTitle={dataQa}>
                {children}
            </Title>
            <VSpacing default={24} />
        </>
    );
};

export default Header;
