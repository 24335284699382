import { useEffect, PropsWithChildren, FC } from 'react';
import { useForm } from 'react-final-form';

const LoginStep: FC<PropsWithChildren> = ({ children }) => {
    const {
        mutators: { removeValue },
    } = useForm();

    useEffect(() => {
        removeValue('code2fa');
    }, [removeValue]);

    return <>{children}</>;
};

export default LoginStep;
