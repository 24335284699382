import { Field } from 'react-final-form';

import { VSpacing, Checkbox, Button, Input, Cell, CellText } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import Hhcaptcha from 'src/components/Hhcaptcha';
import Recaptcha from 'src/components/Recaptcha';
import translation from 'src/components/translation';

const CHECK_2FA_TOTP_WRONG_CODE_STATE = '2fa_totp_check_wrong_code';

const TrlKeys = {
    placeholder: 'auth.twoFactor.code.placeholder',
    submit: 'auth.twoFactor.submit',
    defaultError: 'auth.twoFactor.error.unknown',
    wrongCodeError: 'auth.twoFactor.error.WRONG_CODE',
    trustThisSource: 'auth.twoFactor.totp.trustThisSource',
};

const TwoFactorTotp: TranslatedComponent = ({ verificationType, trls }) => {
    const { isSubmitting, confirmError } = useLoginContext();

    const errorText =
        verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
            ? trls[TrlKeys.wrongCodeError]
            : trls[TrlKeys.defaultError];

    return (
        <>
            <Field
                name="code2fa"
                type="number"
                render={({ input, meta }) => (
                    <div className={''}>
                        <Input
                            {...input}
                            name="code2fa"
                            size="large"
                            placeholder={trls[TrlKeys.placeholder]}
                            data-qa="login-form-code-2fa"
                            invalid={
                                !!confirmError ||
                                (meta.invalid && meta.submitFailed) ||
                                verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
                            }
                            errorMessage={errorText}
                            autoFocus
                        />
                        <VSpacing default={12} />
                    </div>
                )}
            />
            <Cell left={<Checkbox defaultChecked={false} data-qa="totp-trust-this-source" name="trust_this_source" />}>
                <CellText>{trls[TrlKeys.trustThisSource]}</CellText>
            </Cell>
            <Recaptcha />
            <Hhcaptcha />
            <VSpacing default={24} />
            <Button
                size="large"
                style="accent"
                mode="primary"
                data-qa="account-login-submit"
                stretched
                type="submit"
                disabled={isSubmitting}
            >
                {trls[TrlKeys.submit]}
            </Button>
        </>
    );
};

export default translation(TwoFactorTotp);
