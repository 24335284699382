import { VSpacing, Title, Button, Divider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import { AuthMethod, LoginSteps } from 'src/components/AccountLogin/types';
import ContactPhones from 'src/components/ContactPhones';
import Messengers from 'src/components/ContactPhones/Messengers';
import SupportChatPlate from 'src/components/SupportChatPlate';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

interface NoAccessToEmailInfoStepProps {
    backHandler: () => void;
}

const TrlKeys = {
    info: 'auth.twoFactor.noAccessEmail.info',
    back: 'auth.twoFactor.noAccessEmail.back',
    regionsTel: 'helpPhone.phone.regions',
    mskTel: 'helpPhone.phone.area.1',
    spbTel: 'helpPhone.phone.area.2',
    regions: {
        msk: 'helpPhone.title.msk',
        spb: 'helpPhone.title.spb',
        regions: 'helpPhone.title.regions',
    },
    andRegions: 'employer.auth.city.region',
    byPassword: 'login.action.by.password',
    codeNotFound: 'login.form.codeNotFound',
};

const NoAccessToEmailInfoStep: TranslatedComponent<NoAccessToEmailInfoStepProps> = ({ trls, backHandler }) => {
    const isZP = useIsZarplataPlatform();
    const { authMethod, onByPassword, setStep } = useLoginContext();

    const handleByPassword = () => {
        onByPassword?.();
        setStep?.(LoginSteps.Login);
    };

    return (
        <>
            <Title Element="h1" size="small">
                {authMethod === AuthMethod.ByCode ? trls[TrlKeys.codeNotFound] : trls[TrlKeys.info]}
            </Title>
            <VSpacing default={24} />

            {!isZP && (
                <>
                    <SupportChatPlate />
                    <VSpacing default={12} />
                    <Messengers />
                    <VSpacing default={20} />
                </>
            )}
            <ContactPhones showHeader={false} formatted />
            <VSpacing default={20} />

            {authMethod === AuthMethod.ByCode && (
                <>
                    <Divider mode="horizontal" marginTop={20} marginBottom={20} />
                    <Button
                        size="large"
                        style="accent"
                        mode="secondary"
                        data-qa="no-code-login-by-password"
                        stretched
                        onClick={handleByPassword}
                    >
                        {trls[TrlKeys.byPassword]}
                    </Button>
                    <VSpacing default={12} />
                </>
            )}

            <Button
                size="large"
                style="accent"
                mode={authMethod === AuthMethod.ByCode ? 'tertiary' : 'secondary'}
                data-qa="account-login-no-access-email-back"
                stretched
                onClick={backHandler}
            >
                {trls[TrlKeys.back]}
            </Button>
        </>
    );
};

export default translation(NoAccessToEmailInfoStep);
