export enum LoginSteps {
    Login = 'LoginStep',
    NoAccessToEmailStep = 'noAccessToEmailStep',
    TwoFactorStep = 'twoFactorStep',
    MailruError = 'mailruError',
    AccountBlocked = 'accountBlocked',
    OtpVerification = 'otpVerification',
}

export enum TwoFactorCheckStatus {
    TwoFactorCheck = '2fa_check',
    TwoFactorTotpCheck = '2fa_totp_check',
    TwoFactorTotpCheckWrongCode = '2fa_totp_check_wrong_code',
    TwoFactorPushCheck = '2fa_push_check',
}

export interface LoginFormValues {
    username: string;
    password: string;
    code2fa?: string;
    code?: string;
}

export enum AuthMethod {
    ByPassword = 'by_password',
    ByCode = 'by_code',
}
